import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconFeedback = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 56 59"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.636 26.798c0 14.524-8.395 27.007-31.182 25.738-6.71 5.26-15.254 5.82-15.254 5.82s3.445-9.73 0-12.36C3.826 41.197.468 34.372.468 26.798.468 12.274 12.818.5 28.052.5s27.584 11.774 27.584 26.298Zm-27.74-12.792c1.156 0 2.094.941 2.094 2.101v8.404c0 .774.625 1.401 1.396 1.401h8.378c1.157 0 2.094.94 2.094 2.101 0 1.16-.937 2.101-2.094 2.101h-8.378c-.77 0-1.396.627-1.396 1.4v8.405c0 1.16-.938 2.1-2.095 2.1a2.098 2.098 0 0 1-2.094-2.1v-8.404c0-.774-.625-1.401-1.396-1.401h-8.378a2.098 2.098 0 0 1-2.095-2.101c0-1.16.938-2.101 2.095-2.101h8.378c.77 0 1.396-.627 1.396-1.4v-8.405c0-1.16.938-2.1 2.095-2.1Z"
      fill={props.color}
    />
  </svg>
)

export default IconFeedback
