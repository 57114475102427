import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconHiring = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 65 69"
    fill="none"
  >
    <path
      fill={props.color}
      d="M51.785 53.15h1.576c.281 0 .55.111.749.31l10.513 10.543a.528.528 0 0 1 0 .747l-3.86 3.86a.529.529 0 0 1-.747 0L49.474 58.096a1.057 1.057 0 0 1-.31-.748V55.77c0-.274-.107-.537-.297-.734l-.538-.558 2.163-2.164.559.538c.197.19.46.297.734.297Z"
    />
    <path
      fill={props.color}
      d="M61.016 30.905c0 16.788-13.61 30.397-30.397 30.397C13.831 61.302.222 47.693.222 30.905.222 14.117 13.832.508 30.62.508c16.788 0 30.397 13.61 30.397 30.397Zm-55.654 0c0 13.95 11.308 25.257 25.257 25.257 13.949 0 25.257-11.308 25.257-25.257 0-13.949-11.308-25.257-25.257-25.257-13.949 0-25.257 11.308-25.257 25.257Z"
    />
    <path
      fill={props.color}
      d="M49.233 40.696a17.431 17.431 0 0 0-5.271-.799c-1.144 0-2.222.104-3.232.292a20.837 20.837 0 0 1 4.566 2.817c.523.428 1.02.88 1.486 1.35a21.065 21.065 0 0 0 2.451-3.66ZM44.987 46.258c-1.997-2.041-4.816-3.801-8.295-4.757a21.87 21.87 0 0 0-5.8-.763c-6.38 0-11.37 2.577-14.362 5.777a20.95 20.95 0 0 0 14.089 5.418 20.955 20.955 0 0 0 14.368-5.675ZM14.702 44.646a18.769 18.769 0 0 1 1.768-1.64 20.817 20.817 0 0 1 4.406-2.743 17.522 17.522 0 0 0-3.6-.365c-1.897 0-3.663.29-5.27.798a21.073 21.073 0 0 0 2.696 3.95ZM30.891 37.105c2.848 0 5.393-1.563 6.912-3.99.925-1.478 1.469-3.276 1.469-5.237 0-1.5-.34-2.906-.936-4.14-1.406-2.906-4.242-4.86-7.445-4.86-4.562 0-8.401 4.005-8.38 9.02 0 5.161 3.798 9.207 8.38 9.207ZM39.558 35.177c1.228 1.01 2.75 1.603 4.383 1.603 3.984 0 7.307-3.53 7.307-8.009.02-4.355-3.344-7.844-7.307-7.844-1.294 0-2.526.37-3.598 1.022a12.077 12.077 0 0 1 1.543 5.93c0 2.723-.86 5.27-2.328 7.298ZM21.298 22.214a12.018 12.018 0 0 0-1.401 5.69c.001 2.583.78 5.008 2.119 6.98-1.28 1.188-2.933 1.897-4.719 1.897-3.984 0-7.307-3.53-7.307-8.009-.02-4.355 3.344-7.843 7.307-7.843 1.46 0 2.841.47 4.001 1.285Z"
    />
  </svg>
)

export default IconHiring
