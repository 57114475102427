import { ICompanyMember } from './Company'

export interface ICultureEvaluationPure {
  _id?: any
  companyId: string
  termNumber: number
  senderId: string
  memberId: string
  assessment: ICultureEvaluationAssessmentPure[]
  dateCreated: Date
  dateSubmitted?: Date
  status: CultureEvaluationStatus
}

export interface ICultureEvaluation extends ICultureEvaluationPure {
  member: ICompanyMember
  sender: ICompanyMember
}

export type ICultureEvaluationBasics = Pick<
  ICultureEvaluationPure,
  '_id' | 'senderId' | 'status' | 'memberId' | 'assessment'
>
export type ICultureEvaluationPrivacyBasics = Pick<
  ICultureEvaluationPure,
  '_id' | 'senderId' | 'status' | 'memberId'
>
export interface ICultureEvaluationPhaseStatus {
  planningStarted: boolean
  planningFinalized: boolean
  cultureEvaluationsCompleted: boolean
}

export interface ICultureEvaluationCompanyStatusResponse {
  termCultureEvaluations: ICultureEvaluationPrivacyBasics[]
  cultureEvaluationState: ICultureEvaluationPhaseStatus
}

export interface ICultureEvaluationAssessmentPure {
  habitId: string
  alignmentValue: number
  textFeedback: string
}
export enum CultureEvaluationStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
}

export interface ICultureEvaluationResultAggregation {
  memberId: string
  external: boolean
  results: { [key: string]: number }
}

export interface ICultureEvaluationInsightsAggregation {
  memberId: string
  avgCultureEvaluationResult: number
  memberCultureEvaluations: number
}

export interface ICompanyCultureEvaluationPlanning {
  assignedCultureEvaluationsPerMember: Map<string, number>
  memberCultureEvaluations: Map<string, ICultureEvaluationPrivacyBasics[]>
  cultureEvaluationsOverview: ICultureEvaluationOverview[]
  cultureEvaluationState: ICultureEvaluationPhaseStatus
}

export interface ICultureEvaluationOverview {
  memberId: string
  assessments: ICultureEvaluationPrivacyBasics[]
}

export interface ICultureEvaluationPlanningModal {
  assignedCultureEvaluationsPerMember: Map<string, number>
  cultureEvaluationsOverview: ICultureEvaluationOverview[]
}

export interface IMemberCultureEvaluationsStatisticsPure {
  chartData: ICultureEvaluationResultAggregation[] | undefined
  alignmentsAvailable: boolean
  cultureEvaluations?: ICultureEvaluationPure[]
}

export interface IAssessmentDetailed extends ICultureEvaluationPrivacyBasics {
  assessor?: ICompanyMember
}

export interface IAssesseeDetailed extends ICultureEvaluationOverview {
  assesseeDetails?: ICompanyMember
  assessments: IAssessmentDetailed[]
}
