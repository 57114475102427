import { createBrowserHistory } from 'history'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router'
import { GoogleOAuthProvider } from '@react-oauth/google'
import ReactGA from 'react-ga4'

// Utils
import routes from './constants/routes'
import initGa from './utils/googleAnalytics'
import './i18n'

// Components
import MainSkeleton from './components/fallBack/MainSkeleton'
import App from './App'

const googleTag = process.env.REACT_APP_GOOGLE_TAG
if (googleTag) ReactGA.initialize(process.env.REACT_APP_GOOGLE_TAG || '')

const history = createBrowserHistory()
initGa(history)
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || ''
export const WrappedApp = (
  <Suspense fallback={<MainSkeleton />}>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <Router history={history}>
        <App routes={routes} />
      </Router>
    </GoogleOAuthProvider>
  </Suspense>
)

export const init = (el: HTMLElement | null) => {
  if (el) {
    ReactDOM.render(WrappedApp, el)
  }
}

init(document.getElementById('root'))
