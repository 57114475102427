import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconGiveFeedback = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 15 14"
    fill="none"
  >
    <path
      d="M6.364 6.38c1.428 0 2.618-1.275 2.618-2.908 0-1.587-1.197-2.836-2.618-2.836-1.415 0-2.618 1.262-2.612 2.843 0 1.626 1.184 2.9 2.612 2.9ZM2.382 12.454h7.495c.023-.33.158-.655.407-.909l.57-.583H8.045a1.41 1.41 0 0 1-1.401-1.417 1.41 1.41 0 0 1 1.401-1.417h1.107a6.51 6.51 0 0 0-2.79-.604C3.026 7.524.91 9.814.91 11.48c0 .618.373.976 1.473.976Z"
      fill={props.color}
    />
    <path
      d="M12.285 8.856c.11 0 .164-.135.087-.214l-1.568-1.605a.721.721 0 0 1 0-1.003.682.682 0 0 1 .98 0l2.739 2.802a1.02 1.02 0 0 1 0 1.419l-2.739 2.802a.682.682 0 0 1-.98 0 .719.719 0 0 1 0-1.004l1.568-1.604c.077-.079.022-.214-.087-.214H8.046a.682.682 0 0 1-.674-.69c0-.38.302-.69.674-.69h4.24Z"
      fill={props.color}
    />
  </svg>
)

export default IconGiveFeedback
