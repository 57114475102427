import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconHighFive = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 27 33"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.87 30.422c-2.91.644-5.626-.392-8.062-3.106l-3.777-4.2a3.168 3.168 0 0 1-.576-.891c-.434-1.054.059-2.118 1.073-2.535.686-.283 1.47-.129 2.132.517l1.301 1.325c.036.032.079.05.118.033.05-.02.054-.069.033-.118l-3.284-7.974c-.479-1.164-.056-2.199 1.058-2.657.407-.168.837-.17 1.221-.026-.292-1.078.106-1.974 1.09-2.38.965-.397 1.907-.03 2.462.894a1.49 1.49 0 0 1 .805-.785c1.034-.426 2.018.017 2.473 1.121l.455 1.104c.153-.39.5-.706.917-.878 1.034-.426 2.061.035 2.524 1.158l3.517 8.541-1.345-3.267 2.68-6.644a1.17 1.17 0 0 1 1.511-.642c.588.237.87.919.633 1.507l-3.5 8.676c.295.189.562.389.791.597l3.017-7.479a1.152 1.152 0 0 1 2.134.861l-4.22 10.46c-.14.35-.049.526.16.61.16.065.368.01.636-.253l2.561-2.353c.85-.759 1.38-1.009 2.009-.755.568.23.813.745.595 1.284-.16.397-.74 1.1-1.274 1.746l-.315.383c-1.457 1.801-2.761 3.339-4.125 4.505-2.222 1.994-4.548 2.592-7.428 1.622Zm4.102-10.476c1.646 3.998.432 7.149-3.088 8.598-2.774 1.143-5.43.736-8.039-2.155l-3.912-4.331c-.38-.413-.412-.83-.081-1.13.295-.272.724-.274 1.089.075l2.746 2.602c.434.403.797.44 1.155.292.418-.172.587-.579.383-1.076l-4.03-9.784c-.175-.427-.01-.844.398-1.012.407-.168.819.011.995.44l2.92 7.088c.142.348.551.494.89.355a.666.666 0 0 0 .372-.875l-3.427-8.322c-.176-.427-.015-.854.383-1.018.417-.172.833.017 1.009.445l3.427 8.322c.151.368.552.494.89.354a.665.665 0 0 0 .373-.874l-2.92-7.09c-.176-.427-.01-.844.407-1.016.408-.168.8.02.975.448l3.128 7.596c.152.368.563.49.9.35a.663.663 0 0 0 .363-.87l-2.092-5.08c-.176-.428.003-.84.411-1.007.418-.172.805.005.98.433l3.395 8.242Z"
      fill={props.color}
    />
    <path
      d="m15.2 11.918 1.254 3.043 1.951-4.837a1.152 1.152 0 0 0-.636-1.497 1.152 1.152 0 0 0-1.498.636l-1.07 2.655Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.942.526c.28.006.503.239.496.52l-.088 3.796a.508.508 0 1 1-1.015-.024l.087-3.796a.508.508 0 0 1 .52-.496Zm6 1.98a.508.508 0 0 1 .11.71l-2.238 3.068a.508.508 0 1 1-.821-.6l2.24-3.066a.508.508 0 0 1 .709-.111ZM7.2 2.578a.508.508 0 0 1 .712.097l2.298 3.024a.508.508 0 0 1-.809.614L7.103 3.29a.508.508 0 0 1 .097-.712Z"
      fill={props.color}
    />
  </svg>
)

export default IconHighFive
