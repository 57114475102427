import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconEngagement = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 67 67"
    fill="none"
  >
    <path
      fill={props.color}
      d="M66.056 33.028c0 18.24-14.787 33.028-33.028 33.028S0 51.269 0 33.028 14.787 0 33.028 0c18.24 0 33.028 14.787 33.028 33.028Zm-60.471 0c0 15.156 12.287 27.443 27.443 27.443S60.47 48.184 60.47 33.028 48.184 5.585 33.028 5.585 5.585 17.872 5.585 33.028Z"
    />
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M27.702 15.703a2.678 2.678 0 0 1 2.496 1.683l9.637 24.093 3.9-6.274a2.678 2.678 0 0 1 2.274-1.264h17.749v5.355h-16.26l-5.91 9.505a2.677 2.677 0 0 1-4.76-.419l-9.09-22.725-4.673 11.938a2.677 2.677 0 0 1-2.493 1.701H3.446v-5.355h15.299l6.474-16.537a2.678 2.678 0 0 1 2.483-1.7Z"
      clipRule="evenodd"
    />
  </svg>
)

export default IconEngagement
