import { css } from 'styled-components'
import {
  ITextRules,
  ITextStyle,
  TextStyleType,
  ITextStyleConfig,
  FontWeight,
  TextTransformType,
} from '../common/models/typography'
import { Breakpoint } from '../styles/responsive'
import { applyResponsive } from './responsive'

/**
 * Configure the text style presets
 */
export const textStyles: ITextStyleConfig = {
  displayXL: {
    mobile: {
      fontSize: 32,
      lineHeight: 36,
      fontWeight: FontWeight.SemiBold,
    },
    desktop: {
      fontSize: 64,
      lineHeight: 68,
      fontWeight: FontWeight.SemiBold,
    },
  },
  displayL: {
    mobile: {
      fontSize: 28,
      lineHeight: 32,
      fontWeight: FontWeight.SemiBold,
    },
    desktop: {
      fontSize: 48,
      lineHeight: 52,
      fontWeight: FontWeight.SemiBold,
    },
  },
  displayM: {
    mobile: {
      fontSize: 24,
      lineHeight: 28,
      fontWeight: FontWeight.SemiBold,
    },
    desktop: {
      fontSize: 32,
      lineHeight: 36,
      fontWeight: FontWeight.SemiBold,
    },
  },
  displayS: {
    mobile: {
      fontSize: 20,
      lineHeight: 24,
      fontWeight: FontWeight.SemiBold,
    },
    desktop: {
      fontSize: 24,
      lineHeight: 28,
      fontWeight: FontWeight.SemiBold,
    },
  },
  title: {
    mobile: {
      fontSize: 18,
      lineHeight: 24,
      fontWeight: FontWeight.SemiBold,
    },
  },
  subtitle: {
    mobile: {
      fontSize: 14,
      lineHeight: 20,
      fontWeight: FontWeight.SemiBold,
      textTransform: TextTransformType.Uppercase,
      letterSpacing: 0.4,
    },
  },
  body: {
    mobile: {
      fontSize: 16,
      lineHeight: 24,
      fontWeight: FontWeight.Regular,
    },
  },
  bodySmall: {
    mobile: {
      fontSize: 14,
      lineHeight: 20,
      fontWeight: FontWeight.Regular,
    },
  },
  caption: {
    mobile: {
      fontSize: 12,
      lineHeight: 16,
      fontWeight: FontWeight.Regular,
    },
  },
  captionHeavy: {
    mobile: {
      fontSize: 12,
      lineHeight: 16,
      fontWeight: FontWeight.SemiBold,
    },
  },
  captionSmall: {
    mobile: {
      fontSize: 10,
      lineHeight: 14,
      fontWeight: FontWeight.Regular,
    },
  },
  labelHeavy: {
    mobile: {
      fontSize: 14,
      lineHeight: 16,
      fontWeight: FontWeight.SemiBold,
    },
  },
  labelItalic: {
    mobile: {
      fontSize: 14,
      lineHeight: 16,
      fontStyle: 'italic',
    },
  },
  labelSmallCaps: {
    mobile: {
      fontSize: 12,
      lineHeight: 16,
      fontWeight: FontWeight.SemiBold,
      textTransform: TextTransformType.Uppercase,
      letterSpacing: 0.48,
    },
  },
  label: {
    mobile: {
      fontSize: 14,
      lineHeight: 16,
      fontWeight: FontWeight.Regular,
    },
  },
  labelSmall: {
    mobile: {
      fontSize: 10,
      lineHeight: 16,
      fontWeight: FontWeight.SemiBold,
    },
  },
  labelTinyCaps: {
    mobile: {
      fontSize: 10,
      lineHeight: 14,
      fontWeight: FontWeight.SemiBold,
      textTransform: TextTransformType.Uppercase,
      letterSpacing: 0.3,
    },
  },
}

export const defaultTextStyle: TextStyleType = 'body'

/**
 * Define which breakpoint to use to toggle between desktop/mobile
 * text styles
 */
export const defaultTypographyBreakpoint = Breakpoint.L

/**
 * Create the needed CSS for a given text style (works like a mixin)
 * @param textStyle The ITextStyle object, containing desktop styles
 * and optionally, mobile ones
 */
const applyCustomTextStyle = (textStyle: ITextStyle) => {
  const breakpoint = textStyle.breakpoint || defaultTypographyBreakpoint
  return css`
    ${getTextRules(textStyle.mobile)}
    ${textStyle.desktop &&
    applyResponsive({ from: breakpoint }, getTextRules(textStyle.desktop))}
  `
}

/**
 * Return a FlattenSimpleInterpolation css`` literal for given
 * text rules
 * @param rules The ITextRules object
 */
const getTextRules = (rules: ITextRules) => {
  return css`
    font-size: ${rules.fontSize}px;
    line-height: ${rules.lineHeight}px;
    ${rules.fontFamily !== undefined &&
    css`
      font-family: '${rules.fontFamily}';
    `}
    ${rules.fontWeight !== undefined &&
    css`
      font-weight: ${rules.fontWeight};
    `}
    ${rules.textTransform !== undefined &&
    css`
      text-transform: ${rules.textTransform};
    `}
    ${rules.fontStyle !== undefined &&
    css`
      font-style: ${rules.fontStyle};
    `}
    ${rules.letterSpacing !== undefined &&
    css`
      letter-spacing: ${rules.letterSpacing}px;
    `}
  `
}

/**
 * Apply a predefined text style (works like applyCustomTextStyle, but
 * takes as input a preset name, instead of an ITextRules object)
 * @param name The text style key, eg. 'caption' or 'labelHeavy'
 */
const applyTextStyle = (name: TextStyleType) => {
  const textStyle = textStyles[name]
  return applyCustomTextStyle(textStyle)
}

/**
 * Apply ellipsis-style text overflow
 */
const applyTextEllipsis = () => {
  return css`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `
}

/**
 * Remove text ellipsis style (unset certain properties)
 */
const removeTextEllipsis = () => {
  return css`
    text-overflow: unset;
    white-space: unset;
    overflow: unset;
  `
}

const markDownStyles = () => css`
  > *:last-child {
    margin: 0;
  }

  * {
    white-space: pre-wrap;
  }

  ul,
  ol {
    white-space: normal;
    margin: 0 0 12px;
    padding-left: 20px;
  }

  p {
    margin: 0 0 12px;
  }

  h1 {
    margin: 0 0 12px;
    font-size: 18px;
  }

  h2,
  h3,
  h4,
  h5 {
    margin: 0 0 12px;
    font-size: 16px;
  }

  li {
    white-space: normal;
    margin: 0 0 6px;
  }
`

export {
  Breakpoint,
  applyCustomTextStyle,
  applyTextStyle,
  applyTextEllipsis,
  removeTextEllipsis,
  markDownStyles,
}
