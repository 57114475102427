import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconOverdue = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 66 66"
  >
    <path
      d="M15.6587 6.38762C16.9462 4.82218 16.7209 2.50944 15.1555 1.22196C13.59 -0.0655234 11.2773 0.159799 9.98981 1.72523L1.72706 11.7718C0.439573 13.3372 0.664896 15.65 2.23033 16.9374C3.79576 18.2249 6.1085 17.9996 7.39599 16.4342L15.6587 6.38762Z"
      fill={props.color}
    />
    <path
      d="M33.2582 47.1697C35.4258 47.1697 37.1829 45.4126 37.1829 43.245C37.1829 41.0775 35.4258 39.3204 33.2582 39.3204C31.0907 39.3204 29.3336 41.0775 29.3336 43.245C29.3336 45.4126 31.0907 47.1697 33.2582 47.1697Z"
      fill={props.color}
    />
    <path
      d="M35.2515 35.1745C34.9759 37.3038 31.893 37.3038 31.6175 35.1745L29.0154 15.0673C28.8736 13.9712 29.7272 13.0001 30.8324 13.0001H36.0366C37.1419 13.0001 37.9955 13.9713 37.8536 15.0673L35.2515 35.1745Z"
      fill={props.color}
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M46.1514 54.6627C42.3193 56.6834 37.953 57.8273 33.3196 57.8273C28.5585 57.8273 24.0796 56.6195 20.1723 54.4936L20.1604 54.5145L14.7698 63.8513C14.0432 65.1099 12.4338 65.5411 11.1752 64.8145C9.91653 64.0878 9.4853 62.4784 10.212 61.2198L15.6026 51.883C15.669 51.7679 15.7429 51.6597 15.8231 51.5587C9.6852 46.5062 5.77133 38.8497 5.77133 30.2791C5.77133 15.0646 18.1051 2.73077 33.3196 2.73077C48.5341 2.73077 60.8679 15.0646 60.8679 30.2791C60.8679 38.9705 56.8429 46.7219 50.5552 51.7708C50.5788 51.8075 50.6017 51.8448 50.6237 51.883L56.0143 61.2198C56.741 62.4784 56.3097 64.0878 55.0511 64.8145C53.7925 65.5411 52.1831 65.1099 51.4565 63.8513L46.1514 54.6627ZM55.6049 30.2791C55.6049 42.5869 45.6275 52.5644 33.3196 52.5644C21.0118 52.5644 11.0343 42.5869 11.0343 30.2791C11.0343 17.9712 21.0118 7.99372 33.3196 7.99372C45.6275 7.99372 55.6049 17.9712 55.6049 30.2791Z"
      fill={props.color}
    />
    <path
      d="M51.2303 1.22196C49.6649 2.50944 49.4396 4.82218 50.7271 6.38762L58.9898 16.4342C60.2773 17.9996 62.59 18.2249 64.1555 16.9374C65.7209 15.65 65.9462 13.3372 64.6587 11.7718L56.396 1.72523C55.1085 0.159799 52.7958 -0.0655234 51.2303 1.22196Z"
      fill={props.color}
    />
  </svg>
)

export default IconOverdue
