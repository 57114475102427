import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconReceiveFeedback = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 15 14"
    fill="none"
  >
    <path
      d="M6.364 6.38c1.428 0 2.618-1.275 2.618-2.908 0-1.587-1.197-2.836-2.618-2.836-1.415 0-2.618 1.262-2.612 2.843 0 1.626 1.184 2.9 2.612 2.9ZM2.382 12.454h6.41l-1.653-1.69a1.747 1.747 0 0 1 0-2.436l.643-.658a6.852 6.852 0 0 0-1.418-.146c-3.339 0-5.455 2.29-5.455 3.955 0 .618.373.976 1.473.976Z"
      fill={props.color}
    />
    <path
      d="M7.66 10.255a1.02 1.02 0 0 1 0-1.419l2.738-2.802a.682.682 0 0 1 .98 0c.27.277.27.726 0 1.003L9.81 8.642c-.077.079-.022.214.087.214h4.239c.372 0 .674.309.674.69 0 .38-.302.69-.674.69h-4.24c-.108 0-.163.134-.086.213l1.568 1.604a.722.722 0 0 1 0 1.003.682.682 0 0 1-.98 0l-2.739-2.801Z"
      fill={props.color}
    />
    <path
      d="M11.755 10.962c.04.171.062.34.063.501l-.49-.5h.427Z"
      fill={props.color}
    />
  </svg>
)

export default IconReceiveFeedback
