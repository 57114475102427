import {
  ICompanyHabit,
  ICompanyMemberTeam,
  ICompanyMemberTeamOptionalTeam,
  ICompanySkill,
} from '../common/models/Company'
import {
  CompetencyType,
  IExternalFeedback,
  IFeedback,
  IFeedbackCompetency,
  IFeedbackCompetencyPure,
} from '../common/models/Feedback'

export const notEmpty = <TValue>(
  value: TValue | null | undefined
): value is TValue => {
  return value !== null && value !== undefined
}

export const notEmptyTeam = (
  value: ICompanyMemberTeamOptionalTeam
): value is ICompanyMemberTeam => {
  return value.team !== undefined
}

export const isSkill = (
  competency: ICompanySkill | ICompanyHabit | IFeedbackCompetencyPure,
  pureCompetency: IFeedbackCompetencyPure
): competency is ICompanySkill => {
  return pureCompetency.competency === CompetencyType.skill
}

export const isExternalFeedback = (
  feedback: IFeedback | IExternalFeedback
): feedback is IExternalFeedback => {
  return (feedback as IExternalFeedback).memberCompanyName !== undefined
}

export function isIFeedbackCompetency(
  competency: IFeedbackCompetencyPure | IFeedbackCompetency
): competency is IFeedbackCompetency {
  return (competency as IFeedbackCompetency).name !== undefined
}
