import { ITab } from './../../components/TabsWithContent'
import { ICompanyHabit } from './../models/Company'
import { ICompanyPure, ICompanyTerm, ICompanyLevel } from '../models/Company'
import {
  IFeedbackPhaseStatus,
  FeedbackStatus,
  IFeedbackBasics,
} from '../models/Feedback'
import {
  CultureEvaluationStatus,
  ICultureEvaluationPrivacyBasics,
  ICultureEvaluationPhaseStatus,
} from '../models/CultureEvaluation'

export const getCurrentTerm = (
  company: ICompanyPure
): ICompanyTerm | undefined => {
  return company.termsInformation.terms.find(
    (term) => term.termNumber === company.state.currentTermNumber
  )
}

export const getFeedbackState = (
  term: ICompanyTerm,
  termFeedbacks: IFeedbackBasics[]
): IFeedbackPhaseStatus => ({
  planningStarted:
    term.cultureEvaluationPlanningComplete || termFeedbacks.length > 0,
  planningFinalized: term.cultureEvaluationPlanningComplete,
  feedbackCompleted:
    term.cultureEvaluationPlanningComplete &&
    termFeedbacks.every(
      (assessment) => assessment.status === FeedbackStatus.DONE
    ),
})

export const getCultureEvaluationState = (
  term: ICompanyTerm,
  termCultureEvaluations: ICultureEvaluationPrivacyBasics[]
): ICultureEvaluationPhaseStatus => ({
  planningStarted:
    term.cultureEvaluationPlanningComplete || termCultureEvaluations.length > 0,
  planningFinalized: term.cultureEvaluationPlanningComplete,
  cultureEvaluationsCompleted:
    term.cultureEvaluationPlanningComplete &&
    termCultureEvaluations.every(
      (assessment) => assessment.status === CultureEvaluationStatus.DONE
    ),
})

export const getHabitTabs = (
  habit: ICompanyHabit,
  memberLevel?: ICompanyLevel
): ITab[] => {
  return habit.allLevelsDescription
    ? [
        {
          richText: habit.allLevelsDescription,
          openByDefault: true,
        },
      ]
    : habit.levels
        .map((level) => {
          return {
            tag: level.level?.name,
            richText: level.description,
            openByDefault:
              level.levelId === memberLevel?.cultureLevelId ||
              level.level?.name === memberLevel?.name,
            rank: level.level?.rank,
          }
        })
        .sort((a, b) => a.rank - b.rank)
}
