// Any reusable css effects

export const shadows = {
  depthOne: `0px 1px 0px 0px rgba(53, 41, 101, 0.10);`,
  depthTwo: `0px 2px 1px rgba(53, 41, 101, 0.05), 0px 0px 1px rgba(53, 41, 101, 0.25);`,
  depthThree: `0px 0px 2px rgba(53, 41, 101, 0.2), 0px 2px 10px rgba(53, 41, 101, 0.1);`,
  depthFour: `0px 0px 3px rgba(53, 41, 101, 0.1), 0px 4px 20px rgba(53, 41, 101, 0.15);`,
  depthFive: `0px 0px 4px rgba(53, 41, 101, 0.08), 0px 8px 40px rgba(53, 41, 101, 0.16);`,
  depthSix: `0px 0px 4px 1px rgba(0, 0, 0, 0.12), 0px 8px 20px rgba(53, 41, 101, 0.5);`,
}

export const instantDuration = 120
export const fastDuration = 240
export const slowDuration = 400

export const instantTransition = `${instantDuration}ms ease-in-out`
export const fastTransition = `${fastDuration}ms ease-in-out`
export const slowTransition = `${slowDuration}ms ease-in-out`
