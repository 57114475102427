import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconSkills = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 88 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.283 14.0405L54.9525 29.5736C55.4859 30.654 56.5171 31.4026 57.71 31.5755L74.8604 34.0666C77.8647 34.5032 79.0633 38.1923 76.8902 40.309L64.4802 52.3998C63.6178 53.2405 63.2235 54.4527 63.4277 55.6394L66.3568 72.7122C66.8703 75.7023 63.7299 77.982 61.0433 76.5714L45.7043 68.5113C44.6376 67.9513 43.3624 67.9513 42.2957 68.5113L26.9567 76.5714C24.2701 77.9835 21.1297 75.7023 21.6432 72.7122L24.5723 55.6394C24.7765 54.4527 24.3822 53.2405 23.5198 52.3998L11.1098 40.309C8.93672 38.1909 10.1353 34.5018 13.1396 34.0666L30.29 31.5755C31.4829 31.4026 32.5141 30.654 33.0475 29.5736L40.717 14.0405C42.0589 11.3198 45.9397 11.3198 47.283 14.0405Z"
      fill={props.color}
    />
  </svg>
)

export default IconSkills
